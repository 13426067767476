import { Module } from 'vuex';
import to from 'await-to-js';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase, bloqifyFunctions, bloqifyStorage } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { Investor } from '@/models/users/User';
import { PaymentStatus, PaymentProvider, Investment, Payment } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';
import { assetChecks } from './asset';
import { ceilNumber } from '../utils/numbers';
import { generateFileMd5Hask } from '../utils/files';

const SET_PAYMENT = 'SET_PAYMENT';

// Payload types
export type CreatePaymentPayload = {
  /** € */
  euroAmount: number,
  sharesAmount: number,
  assetId: string,
  investorId: string,
  paymentDateTime: number,
  dividendsFormat?: Payment['dividendsFormat'],
  endDateTime?: number,
  type?: string,
  sendEmail?: boolean,
}
export type UpdatePaymentPayload = CreatePaymentPayload & {
  investmentId: string,
  paymentId: string
}

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_PAYMENT](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createPayment(
      { commit, getters },
      {
        euroAmount,
        sharesAmount,
        assetId,
        investorId,
        paymentDateTime,
        dividendsFormat,
        endDateTime: paymentEndDateTime,
        type,
        sendEmail,
      }: CreatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'createPayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          // Collection references
          const insertedAssetRef = bloqifyFirestore.collection('assets').doc(assetId);
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorRef = bloqifyFirestore.collection('investors').doc(investorId);
          let investmentRef = investmentsRef.doc();
          let paymentRef = investmentRef.collection('payments').doc();

          const [readAssetError, readAssetSuccess] = await to(transaction.get(insertedAssetRef));
          if (readAssetError || !readAssetSuccess?.exists) {
            throw readAssetError || Error('Asset not found.');
          }

          const asset = readAssetSuccess!.data() as Asset;

          if (!assetChecks(asset)) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          const [readInvestorError, readInvestorSuccess] = await to(transaction.get(investorRef));
          if (readInvestorError || !readInvestorSuccess?.exists) {
            throw readInvestorError || Error('Investor not found.');
          }

          const investor = readInvestorSuccess!.data() as Investor;

          const {
            euroMin, sharesAvailable, emissionCost, endDateTime,
            returnsAfterEnd, fixedDividends, startDateTime, type,
            totalValueShares,
          } = asset;

          const euroAmountNum = Number(euroAmount);
          const sharesAmountNum = Number(sharesAmount);

          if (isNaN(euroAmount) || isNaN(sharesAmount)) {
            throw Error('Euro amount and shares amount should be numbers');
          }
          if (new BigNumber(euroAmount).dividedBy(sharesAmount).toNumber() < 0) {
            throw Error('Euro amount and shares amount should be both either positive or negative');
          }

          if (sharesAmount > sharesAvailable) {
            throw Error('The asset does not have this many available shares.');
          }

          // Client side we cannot use transaction to get DocumentQueries
          const [getInvestmentError, getInvestmentSuccess] = await to(
            investmentsRef.where('asset', '==', insertedAssetRef).where('investor', '==', investorRef).get(),
          );
          if (getInvestmentError) {
            throw getInvestmentError;
          }

          const foundInvestments = !getInvestmentSuccess?.empty;
          const investmentDoc = foundInvestments ? getInvestmentSuccess!.docs[0] : undefined;
          let firstInvestment = false;

          if (foundInvestments) {
            investmentRef = investmentDoc!.ref;
            const [getPaymentsError, getPaymentsSuccess] = await to(
              investmentRef.collection('payments').get(),
            );
            if (getPaymentsError) {
              throw getPaymentsError;
            }
            if (getPaymentsSuccess!.empty) {
              throw Error('There was an error retrieving previous investments information.');
            }
            firstInvestment = !getPaymentsSuccess!.docs.some((snap): boolean => snap.get('providerData.status') === PaymentStatus.Paid);

            // Reset paymentRef
            paymentRef = investmentRef.collection('payments').doc();
          }

          // Only check the minimum amount if it is the first investment
          if (((foundInvestments && firstInvestment) || !foundInvestments) && euroAmountNum < euroMin) {
            throw Error(`The amount has to be higher than the minimum (${euroMin} eur).`);
          }

          if (startDateTime && firebase.firestore.Timestamp.fromMillis(paymentDateTime) < startDateTime) {
            throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
          }

          let years = 0;
          if (fixedDividends) {
            const numberedEndDateTime = endDateTime?.toMillis();
            // The paymentDateTime should already be in milliseconds as it comes from the frontend
            const paymentDateFormatted = moment(paymentDateTime);
            const yearsTemp = moment(numberedEndDateTime).diff(paymentDateFormatted, 'years');

            // If no years remaining, put 1
            years = yearsTemp > 0 ? yearsTemp : 1;
          } else if (type === 'equity' && !dividendsFormat) {
            throw Error('Missing dividends format.');
          }

          const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime);
          const dateNow = firebase.firestore.Timestamp.now();

          // Adding the emission cost on top of the euro amount
          const amountWithEmissionCost = new BigNumber(100)
            .plus(emissionCost)
            .dividedBy(100)
            .times(euroAmountNum)
            .toNumber();

          const newDividensFormat: [string, number] | undefined = type === 'equity' && asset.fixedDividends
            ? [years.toString(), returnsAfterEnd || 0] : dividendsFormat;

          if (newDividensFormat === undefined) {
            throw Error('Missing dividends format.');
          }

          const newPayment: Payment = {
            asset: insertedAssetRef,
            investment: investmentRef,
            investor: investorRef,
            createdDateTime: dateNow,
            paymentDateTime: paymentDate,
            id: paymentRef.id,
            provider: PaymentProvider.Custom,
            updatedDateTime: dateNow,
            deleted: false,
            dividendsFormat: newDividensFormat,
            providerData: {
              id: paymentRef.id,
              amount: {
                currency: 'EUR',
                value: ceilNumber(amountWithEmissionCost, 2),
              },
              metadata: {
                uid: investorId,
                euroAmount: euroAmountNum,
                sharesAmount: sharesAmountNum,
                investmentId: investmentRef.id,
                assetId: insertedAssetRef.id,
                paymentId: paymentRef.id,
              },
              status: PaymentStatus.Paid,
            },
            ...(paymentEndDateTime && {
              endDateTime: firebase.firestore.Timestamp.fromMillis(
                paymentEndDateTime,
              ),
            }),
            ...(type && { type }),
          };

          // Setting new investment or updating old one
          if (!foundInvestments) {
            const investment: Omit<Investment, 'id'> = {
              asset: insertedAssetRef,
              createdDateTime: dateNow,
              updatedDateTime: dateNow,
              investor: investorRef,
              paidEuroTotal: euroAmountNum,
              boughtSharesTotal: sharesAmountNum,
            };

            transaction.set(investmentRef, investment);
          } else {
            const investment = investmentDoc!.data() as Investment;
            transaction.update(
              investmentRef,
              {
                paidEuroTotal: new BigNumber(investment.paidEuroTotal || 0).plus(euroAmountNum).toNumber(),
                boughtSharesTotal: new BigNumber(investment.boughtSharesTotal || 0).plus(sharesAmountNum).toNumber(),
                updatedDateTime: dateNow,
              },
            );
          }

          transaction.set(paymentRef, newPayment);
          transaction.update(
            insertedAssetRef,
            {
              sharesAvailable: firebase.firestore.FieldValue.increment(-sharesAmountNum),
              updatedDateTime: dateNow,
            },
          );
          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            { paidPayments: firebase.firestore.FieldValue.increment(1) },
          );

          if (sendEmail) {
            const [sendEmailError] = await to(bloqifyFunctions.httpsCallable('sendSharesEmail')({
              lang: 'nl',
              investor: {
                email: investor.email,
                surname: investor.surname,
                name: investor.name,
              },
              asset: {
                name: asset.name,
                clientName: asset.clientName,
              },
              payment: {
                euroAmount: euroAmountNum,
                sharesAmount: sharesAmountNum,
              },
            }));
            if (sendEmailError) {
              console.error(sendEmailError);
            }
          }

          // Return the target
          return { investmentId: investmentRef.id, paymentId: paymentRef.id };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'createPayment' });
    },
    async updatePayment(
      { commit, getters },
      {
        euroAmount,
        sharesAmount,
        assetId,
        investorId,
        paymentDateTime,
        investmentId: sourceInvestmentId,
        paymentId: sourcePaymentId,
        dividendsFormat,
        endDateTime: paymentEndDateTime,
        type,
      }: UpdatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'updatePayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          // Collection references
          const assetsRef = bloqifyFirestore.collection('assets');
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorsRef = bloqifyFirestore.collection('investors');

          const insertedAssetRef = assetsRef.doc(assetId);
          const investmentRef = investmentsRef.doc(sourceInvestmentId);
          const investorRef = investorsRef.doc(investorId);
          const paymentRef = investmentRef.collection('payments').doc(sourcePaymentId);

          // References that will be modified depending on the case for the transaction update/set zone
          let newPaymentRef = paymentRef;
          let newInvestmentRef = investmentRef;

          const [readSourceInvestmentError, sourceInvestmentSuccess] = await to(transaction.get(investmentRef));
          if (readSourceInvestmentError) {
            throw readSourceInvestmentError;
          } else if (!sourceInvestmentSuccess!.exists) {
            throw Error('Investment not found.');
          }

          const sourceInvestment = sourceInvestmentSuccess!.data() as Investment;

          const oldAssetId = sourceInvestment.asset.id;
          const differentAsset = oldAssetId !== assetId;
          const differentInvestor = sourceInvestment.investor.id !== investorId;
          const differentInvestment = differentAsset || differentInvestor;
          let targetInvestment: undefined | { id: string, data: Investment };

          // Different investment means we have to check if there is an investment already there
          if (differentInvestment) {
            const [readTargetInvestmentError, targetInvestmentSuccess] = await to(
              investmentsRef.where('asset', '==', assetsRef.doc(assetId)).where('investor', '==', investorsRef.doc(investorId)).get(),
            );
            if (readTargetInvestmentError) {
              throw readTargetInvestmentError;
            }

            // Assigning the target investment
            if (!(targetInvestmentSuccess!.empty)) {
              targetInvestment = {
                id: targetInvestmentSuccess!.docs[0].id,
                data: targetInvestmentSuccess!.docs[0].data() as Investment,
              };
            }
          }

          const [readSourcePaymentError, sourcePaymentSuccess] = await to(transaction.get(paymentRef));
          if (readSourcePaymentError) {
            throw readSourcePaymentError;
          } else if (!sourcePaymentSuccess?.exists) {
            Error('Payment not found.');
          }

          const sourcePayment = sourcePaymentSuccess!.data() as Payment;

          if (sourcePayment.deleted || sourcePayment.ended) {
            throw Error('Cannot modify an ended or deleted payment.');
          }

          if (sourcePayment.providerData.status !== PaymentStatus.Paid) {
            throw Error('Cannot modify a payment which is not paid.');
          }

          const [readSourceAssetError, sourceAssetSuccess] = await to(transaction.get(insertedAssetRef));
          if (readSourceAssetError) {
            throw readSourceAssetError;
          } else if (!sourceAssetSuccess?.exists) {
            Error('Asset not found.');
          }

          const [reatedSourceAssetError, oldAssetSuccess] = await to(transaction.get(assetsRef.doc(oldAssetId)));
          if (reatedSourceAssetError) {
            throw reatedSourceAssetError;
          } else if (!oldAssetSuccess?.exists) {
            Error('Target asset not found.');
          }

          const targetAsset = sourceAssetSuccess!.data() as Asset;
          const oldAsset = oldAssetSuccess!.data() as Asset;

          const [readInvestorError, readInvestorSuccess] = await to(transaction.get(investorRef));
          if (readInvestorError || !readInvestorSuccess?.exists) {
            throw readInvestorError || Error('Investor not found.');
          }

          const investor = readInvestorSuccess!.data() as Investor;

          // Check both (if necessary) assets have all fields correct
          if ((oldAsset && !assetChecks(oldAsset)) && (differentAsset && !assetChecks(targetAsset))) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          // Select the correct asset
          const {
             euroMin, totalValueShares, emissionCost, endDateTime,
            returnsAfterEnd, fixedDividends, startDateTime,
          } = (targetAsset || oldAsset)!;

          if ((targetAsset || oldAsset).clientName !== investor.clientName) {
            throw Error('The investor has to be allocated to the correct asset');
          }

          const euroAmountNum = Number(euroAmount);
          const sharesAmountNum = Number(sharesAmount);

          if (isNaN(euroAmount) || isNaN(sharesAmount)) {
            throw Error('Euro amount and shares amount should be numbers');
          }
          if (new BigNumber(euroAmount).dividedBy(sharesAmount).toNumber() < 0) {
            throw Error('Euro amount and shares amount should be both either positive or negative');
          }

          // If there was an old payment we might need these values to restore the correct amounts
          const oldPaymentAmount = Number(sourcePayment.providerData.metadata.euroAmount);
          const oldPaymentSharesAmount = Number(sourcePayment.providerData.metadata.sharesAmount);

          const amountIsDifferent = oldPaymentAmount !== euroAmountNum;
          // If it's the same asset, the asset shares available (excluding the used by the current payment),
          // minus the new amount of shares, cannot be under 0.
          // If it's a different asset, we just need to check if the sharesAvaiable from the target minus the shares of the
          // payment coming in are minus 0.
          if ((!differentAsset && ((sharesAmountNum !== oldPaymentSharesAmount)
            && (new BigNumber(targetAsset.sharesAvailable).plus(oldPaymentSharesAmount).minus(sharesAmountNum).toNumber() < 0)))
            || (differentAsset && new BigNumber(targetAsset.sharesAvailable).minus(sharesAmountNum).toNumber() < 0)) {
            throw Error('The asset does not have this many available shares.');
          }

          if ((!targetInvestment?.data.paidEuroTotal || (oldPaymentAmount !== sourceInvestment.paidEuroTotal)) && euroAmountNum < euroMin) {
            throw Error(`The amount has to be higher than the minimum (${euroMin} eur).`);
          }

          if (startDateTime && firebase.firestore.Timestamp.fromMillis(paymentDateTime) < startDateTime) {
            throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
          }

          let years = 0;
          if (fixedDividends) {
            const numberedEndDateTime = endDateTime?.toMillis();
            // The paymentDateTime should already be in milliseconds as it comes from the frontend
            const paymentDateFormatted = moment(paymentDateTime);
            const yearsTemp = moment(numberedEndDateTime).diff(paymentDateFormatted, 'years');

            // If no years remaining, put 1
            years = yearsTemp > 0 ? yearsTemp : 1;
          } else if (!dividendsFormat) {
            throw Error('Missing dividends format.');
          }

          const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime);
          const dateNow = firebase.firestore.Timestamp.now();

          const newPayment: Payment = {
            ...sourcePayment,
            paymentDateTime: paymentDate,
            dividendsFormat: !fixedDividends ? dividendsFormat! : [years.toString(), returnsAfterEnd!],
            updatedDateTime: dateNow,
            ...(paymentEndDateTime && { endDateTime: firebase.firestore.Timestamp.fromMillis(paymentEndDateTime) }),
            ...(type && { type }),
          };

          if (amountIsDifferent) {
            // Adding the emission cost on top of the euro amount
            const amountWithEmissionCost = new BigNumber(100)
              .plus(emissionCost)
              .dividedBy(100)
              .times(euroAmountNum)
              .toNumber();

            newPayment.providerData.amount.value = ceilNumber(amountWithEmissionCost, 2);
            newPayment.providerData.metadata.euroAmount = euroAmountNum;
            newPayment.providerData.metadata.sharesAmount = sharesAmountNum;
          }

          /**
           * TRANSACTION UPDATE / SET ZONE
           */
          if (differentInvestment) {
            if (targetInvestment) {
              newInvestmentRef = investmentsRef.doc(targetInvestment.id);
              // Update target investment
              transaction.update(
                newInvestmentRef,
                {
                  paidEuroTotal: new BigNumber(targetInvestment?.data.paidEuroTotal || 0).plus(euroAmountNum).toNumber(),
                  boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesAmountNum),
                  updatedDateTime: dateNow,
                },
              );

              // Create target payment
              newPaymentRef = investmentsRef.doc(targetInvestment.id).collection('payments').doc();
              transaction.set(
                newPaymentRef,
                {
                  ...newPayment,
                  investor: investorRef,
                  investment: newInvestmentRef,
                  asset: insertedAssetRef,
                  id: newPaymentRef.id,
                } as Payment,
              );
            } else {
              // Create target investment
              newInvestmentRef = investmentsRef.doc();
              transaction.set(
                newInvestmentRef,
                {
                  paidEuroTotal: firebase.firestore.FieldValue.increment(Number(euroAmountNum)),
                  boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesAmountNum),
                  asset: insertedAssetRef,
                  investor: investorRef,
                  createdDateTime: sourceInvestment.createdDateTime,
                  updatedDateTime: dateNow,
                },
              );

              // Create target payment
              newPaymentRef = newInvestmentRef.collection('payments').doc();
              transaction.set(
                newPaymentRef,
                {
                  ...newPayment,
                  investor: investorRef,
                  asset: insertedAssetRef,
                  investment: newInvestmentRef,
                  id: newPaymentRef.id,
                } as Payment,
              );
            }

            // Restore source asset
            transaction.update(
              assetsRef.doc(oldAssetId),
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(oldPaymentSharesAmount),
                updatedDateTime: dateNow,
              },
            );

            // Update target asset
            transaction.update(
              insertedAssetRef,
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(-sharesAmountNum),
                updatedDateTime: dateNow,
              },
            );

            // Delete old payment
            transaction.delete(paymentRef);

            // If no payments left, we remove otherwise just decrease
            if (new BigNumber(sourceInvestment.paidEuroTotal || 0).minus(oldPaymentAmount).toNumber() === 0) {
              transaction.delete(investmentRef);
            } else {
              transaction.update(
                investmentRef,
                {
                  paidEuroTotal: new BigNumber(sourceInvestment?.paidEuroTotal || 0).minus(oldPaymentAmount).toNumber(),
                  boughtSharesTotal: firebase.firestore.FieldValue.increment(-oldPaymentSharesAmount),
                  updatedDateTime: dateNow,
                },
              );
            }
          } else {
            /** € */
            const eAmountDiff = new BigNumber(euroAmountNum).minus(oldPaymentAmount).toNumber();
            /** Shares */
            const sharesDiff = new BigNumber(sharesAmountNum).minus(oldPaymentSharesAmount).toNumber();
            // Update target investment
            transaction.update(
              investmentRef,
              {
                paidEuroTotal: new BigNumber(sourceInvestment?.paidEuroTotal || 0).plus(eAmountDiff).toNumber(),
                boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesDiff),
                updatedDateTime: dateNow,
              },
            );

            // Update target payment
            transaction.update(
              paymentRef,
              newPayment,
            );

            // Update target asset
            transaction.update(
              assetsRef.doc(assetId),
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(-sharesDiff),
                updatedDateTime: dateNow,
              },
            );
          }

          // Return the target
          return { investmentId: newInvestmentRef.id, paymentId: newPaymentRef.id };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updatePayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'updatePayment' });
    },
    async deletePayment(
      { commit },
      { investmentId, paymentId }:
        { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'deletePayment' });

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestment || !readInvestmentSuccess?.exists) {
          throw readInvestment || Error('Error getting the investment.');
        }

        const assetId = readInvestmentSuccess.get('asset').id as string;
        const insertedAssetId = bloqifyFirestore.collection('assets').doc(assetId);

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }

        const payment = readPaymentSuccess.data() as Payment;

        if (payment.deleted) {
          throw Error('This payment was already deleted.');
        }

        const [readAsset, readAssetSuccess] = await to(transaction.get(insertedAssetId));
        if (readAsset || !readAssetSuccess?.exists) {
          throw readAsset || Error('Error getting the asset.');
        }

        const timeNow = firebase.firestore.Timestamp.now();

        // Only restore the share fields if the payment was already paid (and not ended yet). Otherwise the paymentWebHook already took care of it
        if ((payment.providerData.status === PaymentStatus.Paid || payment.providerData.status === PaymentStatus.Open) && !payment.ended) {
          const amount = payment.providerData.metadata.euroAmount;
          const sharesAmount = payment.providerData.metadata.sharesAmount;

          // Update asset (only necessary if removing a payment with a paid or open status)
          transaction.update(
            insertedAssetId,
            {
              sharesAvailable: firebase.firestore.FieldValue.increment(sharesAmount),
              updatedDateTime: timeNow,
            },
          );

          const paidEuroTotal = readInvestmentSuccess.get('paidEuroTotal');

          // Update investment with new totals
          transaction.update(
            investmentRef,
            {
              paidEuroTotal: new BigNumber(paidEuroTotal || 0).minus(amount).toNumber(),
              boughtSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
              updatedDateTime: timeNow,
            },
          );
          // Update counts
          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            { paidPayments: firebase.firestore.FieldValue.increment(-1) },
          );
        }

        // Removing payment
        transaction.update(paymentRef, { deleted: true, updatedDateTime: timeNow });
      }));
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deletePayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'deletePayment' });
    },
    async endPayment(
      { commit },
      { investmentId, paymentId, endDate }:
        { investmentId: string, paymentId: string, endDate?: number },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'endPayment' });

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestment || !readInvestmentSuccess?.exists) {
          throw readInvestment || Error('Error getting the investment.');
        }

        const assetId = readInvestmentSuccess.get('asset').id as string;
        const insertedAssetId = bloqifyFirestore.collection('assets').doc(assetId);

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }

        const payment = readPaymentSuccess.data() as Payment;

        if (payment.ended) {
          throw Error('This payment was already ended.');
        }

        const [readAsset, readAssetSuccess] = await to(transaction.get(insertedAssetId));
        if (readAsset || !readAssetSuccess?.exists) {
          throw readAsset || Error('Error getting the asset.');
        }

        const startDateTime = readAssetSuccess.get('startDateTime') as firebase.firestore.Timestamp;
        const timeNow = firebase.firestore.Timestamp.now();

        if (startDateTime && new Date(endDate || timeNow.toMillis()) < startDateTime.toDate()) {
          throw new Error('The payment cannot be ended before the start date of the selected asset.');
        }

        // Only restore the share fields if the payment was already paid. Otherwise the paymentWebHook already took care of it
        if ((payment.providerData.status === PaymentStatus.Paid || payment.providerData.status === PaymentStatus.Open) && !payment.ended) {
          const amount = payment.providerData.metadata.euroAmount;
          const sharesAmount = payment.providerData.metadata.sharesAmount;

          // Update asset (only necessary if removing a payment with a paid status)
          transaction.update(
            insertedAssetId,
            { sharesAvailable: firebase.firestore.FieldValue.increment(sharesAmount), updatedDateTime: timeNow },
          );

          const paidEuroTotal = readInvestmentSuccess.get('paidEuroTotal');

          // Update investment with new totals
          transaction.update(
            investmentRef,
            {
              paidEuroTotal: new BigNumber(paidEuroTotal || 0).minus(amount).toNumber(),
              boughtSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
              updatedDateTime: timeNow,
            },
          );
        }

        // Ending payment
        transaction.update(
          paymentRef,
          {
            ended: endDate ? firebase.firestore.Timestamp.fromMillis(endDate) : timeNow,
            updatedDateTime: timeNow,
          },
        );

        // Update counts
        transaction.update(
          bloqifyFirestore.collection('settings').doc('counts'),
          { paidPayments: firebase.firestore.FieldValue.increment(-1) },
        );
      }));
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'endPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'endPayment' });
    },
    async cancelPayment(
      { commit },
      { investmentId, paymentId }:
        { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'cancelPayment' });

      const [cancelPaymentError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        // Update investments data
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
        if (getInvestmentError || !getInvestment!.exists) {
          throw getInvestmentError || Error('Error getting the investment.');
        }
        const investment = getInvestment!.data() as Investment;

        const paymentRef = investmentRef.collection('payments').doc(paymentId);
        const [getPaymentError, getPayment] = await to(transaction.get(paymentRef));
        if (getPaymentError || !getPayment!.exists) {
          throw getPaymentError || Error('Error getting the payment.');
        }
        const payment = getPayment!.data() as Payment;
        if (payment.ended) {
          throw Error('This payment was already ended.');
        }
        if (payment.deleted) {
          throw Error('This payment was deleted.');
        }
        if (payment.providerData.status !== PaymentStatus.Open) {
          throw Error('You can only cancel open payments.');
        }

        const assetRef = investment.asset as firebase.firestore.DocumentReference;
        const dateNow = firebase.firestore.Timestamp.now();

        // marking payment as Paid and ending it
        transaction.update(paymentRef, { providerData: { ...payment.providerData, status: PaymentStatus.Canceled } });

        // Update investment
        const amount = payment.providerData.metadata.euroAmount;
        const sharesAmount = payment.providerData.metadata.sharesAmount;
        transaction.update(
          investmentRef,
          {
            openPayments: firebase.firestore.FieldValue.increment(-1),
            paidEuroTotal: new BigNumber(investment.paidEuroTotal || 0).minus(amount).toNumber(),
            boughtSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
            updatedDateTime: dateNow,
          },
        );

        // Update asset (only necessary if removing a payment with a paid or open status)
        transaction.update(
          assetRef,
          {
            sharesAvailable: firebase.firestore.FieldValue.increment(sharesAmount),
            updatedDateTime: dateNow,
          },
        );
      }));

      if (cancelPaymentError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: cancelPaymentError, operation: 'cancelPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'cancelPayment' });
    },
    async markPaymentAsPaid(
      { commit },
      { investmentId, paymentId }:
        { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'markPaymentAsPaid' });

      const [markPaymentAsPaidError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestment || !readInvestmentSuccess?.exists) {
          throw readInvestment || Error('Error getting the investment.');
        }

        const assetRef = readInvestmentSuccess.get('asset');

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }

        const payment = readPaymentSuccess.data() as Payment;

        if (payment.providerData.status === PaymentStatus.Paid) {
          throw Error('This payment is already with the status Paid.');
        }

        const [readAsset, readAssetSuccess] = await to(transaction.get(assetRef));

        if (readAsset || !readAssetSuccess?.exists) {
          throw readAsset || Error('Error getting the asset.');
        }

        const timeNow = firebase.firestore.Timestamp.now();

        // marking payment as Paid and ending it
        transaction.update(paymentRef, { providerData: { ...payment.providerData, status: PaymentStatus.Paid } });

        // Update investment with new totals
        transaction.update(
          investmentRef,
          {
            openPayments: firebase.firestore.FieldValue.increment(-1),
            updatedDateTime: timeNow,
          },
        );
      }));

      if (markPaymentAsPaidError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: markPaymentAsPaidError, operation: 'markPaymentAsPaid' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'markPaymentAsPaid' });
    },
    async deleteDocumentFromPayment(
      { commit },
      { paymentId, investmentId, fileFullPath, fileName }:
        { paymentId: string, investmentId: string, fileFullPath: string, fileName: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'deleteDocumentPayment' });
      const storageRef = bloqifyStorage.ref();
      const fileRef = await storageRef.child(fileFullPath);
      const timeNow = firebase.firestore.Timestamp.now();

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }

        const paymentData = readPaymentSuccess.data();
        const legalDocuments = paymentData?.legalDocuments;

        const legalDocumentToDeleteIndex = legalDocuments.findIndex((v): boolean => v.includes(fileName));

        const newLegalDocuments = [...legalDocuments];
        if (legalDocumentToDeleteIndex !== -1) {
          newLegalDocuments.splice(legalDocumentToDeleteIndex, 1);

          // Update payment legalDocuments
          transaction.update(
            paymentRef,
            { legalDocuments: newLegalDocuments, updatedDateTime: timeNow },
          );

          fileRef.delete();
        }

        return newLegalDocuments;
      }));

      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteDocumentPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'deleteDocumentPayment', payload: transactionSuccess });
    },
    async uploadDocumentToPayment(
      { commit },
      { uploadedFiles, paymentId, investmentId }:
        { uploadedFiles: any, paymentId: string, investmentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'uploadDocumentToPayment' });
      const storageRef = bloqifyStorage.ref();
      const timeNow = firebase.firestore.Timestamp.now();

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }
        const paymentData = readPaymentSuccess.data();
        const legalDocuments = paymentData?.legalDocuments || [];
        const investorId = paymentData?.investor.id;

        const legalDocumentsPaths: string[] = [];
        const storageChildren: { file: File, ref: firebase.storage.Reference }[] = [];

        // Building propper objects: asset (to send to the database) and files (to send to storage)
        // Setting up an array with all the files to be uploaded
        uploadedFiles.forEach((uploadedFile): void => {
          const { file } = uploadedFile;
          const fullPath = `payments/${paymentId}/${investorId}/legalDocuments/${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          legalDocumentsPaths.push(fullPath);
        });

        const newLegalDocuments = legalDocuments.concat(legalDocumentsPaths);

        transaction.update(
          paymentRef,
          { legalDocuments: newLegalDocuments, updatedDateTime: timeNow },
        );

        // Uploading all files including hashes
        try {
          await Promise.all(storageChildren.map(async (child): Promise<any> => {
            const md5Hash = await generateFileMd5Hask(child.file, true);

            return child.ref.put(child.file, { customMetadata: { md5Hash } });
          }));
        } catch (e) {
          return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: e, operation: 'uploadDocumentToPayment' });
        }

        return newLegalDocuments;
      }));

      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'uploadDocumentToPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'uploadDocumentToPayment', payload: transactionSuccess });
    },
  },
  getters: {},
};
