import firebase from 'firebase/app';

export const enum DataContainerStatus {
  Initial = 'initial',
  Processing = 'processing',
  Success = 'success',
  Error = 'error',
}
export interface DataContainerInterface<T = any> {
  status: DataContainerStatus;
  payload: T | null;
  error?: string | Error | firebase.auth.Error;
  name?: string;
  length?: number | null;

  loading(): boolean;
}

export class DataContainer<T> implements DataContainerInterface<T> {
  status: DataContainerStatus = DataContainerStatus.Initial;
  payload = null;
  error = '';
  name = '';
  length = null;

  /**
   * Returns whether data is loading.
   */
  public loading(): boolean {
    return this.status === DataContainerStatus.Initial || this.status === DataContainerStatus.Processing;
  }
}

export interface VuexfireSnapshot {
  id: string;
}

export interface Settings {
  clientNames?: Array<string>,
  maintenance: boolean;
  bloqadminMaintenance: boolean;
  restoring: boolean;
}

export interface IdentificationSettings {
  requireApproval: {
    business: boolean;
    world: boolean;
  }
}

export interface Counts {
  activeInvestors: number;
  activeUsers: number;
  paidPayments: number;
  pendingIdentificationRequests: number;
  publishedAssets: number;
}

export interface Backup {
  location: {
    bucket: string;
    path: string;
  };
  service: object;
}
