import { Module } from 'vuex';
import { State } from '@/models/State';
import asset from './asset';
import user from './user';
import dividend from './dividend';
import identificationRequest from './identificationRequest';
import payment from './payment';
import valuation from './valuation';
import news from './news';
import { Vertebra } from '../utils/skeleton';

export default <{ [key: string]: Module<Vertebra, State> }>{
  asset,
  user,
  valuation,
  news,
  dividend,
  identificationRequest,
  payment,
};
