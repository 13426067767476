import { User, Investor, DataChangeRequest } from '@/models/users/User';
import { Vertebra } from '@/store/utils/skeleton';
import { Valuation } from '@/models/assets/Valuation';
import { DataContainer, DataContainerInterface, Settings, Counts, IdentificationSettings, Backup } from './Common';
import { Manager, SpecialUser } from './manager/Manager';
import { CurrentManager, CurrentManagerInterface } from './manager/CurrentManager';
import { Filters, FilterInterface } from './Filters';
import { Asset } from './assets/Asset';
import { IdentificationRequest } from './identification-requests/IdentificationRequest';
import { Investment, Payment } from './investments/Investment';
import { Trace } from './tracing/Trace';
import { Dividend } from './assets/Dividends';
import { News } from './assets/News';

/**
 * Vuex state. Some rules:
 * - Datacontainer's type can only be a primitive, since store only allows primitives
 *
 * @see https://vuex.vuejs.org/guide/state.html
 */
export interface State {
  // Firestore native bindings
  manager: Manager | null,

  // Computed/derived data
  currentManager: DataContainerInterface<CurrentManagerInterface>;
  login: DataContainerInterface<any>;
  verifySMS: DataContainerInterface<any>;
  managerStatus: DataContainerInterface<string>;
  managerRole: DataContainerInterface<string>;
  managerRemoval: DataContainerInterface<any>,
  tracing: DataContainerInterface<Trace[]>;
  generalSettings: DataContainerInterface<Settings>;
  securitySettings: DataContainerInterface<string>;
  backups: DataContainerInterface<Backup[]>;
  restoreBackup: DataContainerInterface<any>;
  restoreBackupStatus: DataContainerInterface<any>;
  passwordReset: DataContainerInterface<string>;
  approveSensitiveDataChange: DataContainerInterface<string>;
  rejectSensitiveDataChange: DataContainerInterface<string>;
  notification: DataContainerInterface<string>;
  sendTestEmails: DataContainerInterface<string>;
  filters: FilterInterface;
  outlineMode: boolean;
  specialUsers: SpecialUser[];
  settings: Settings | null;
  identificationSettings: IdentificationSettings | null,
  restoration: any[] | null;

  openPayments: Payment[];
  loanPayments: Payment[];

  // The Refactor
  boundInvestments: Investment[];
  investments: Investment[];
  boundInvestment: Investment | null;
  boundPayments: Payment[];
  boundPayment: Payment | null;
  boundAssets: Asset[];
  boundAsset: Asset | null;
  boundUsers: (User | Investor)[];
  boundUser: User | Investor | null;
  boundIdentificationRequests: IdentificationRequest[];
  boundDividends: Dividend[];
  boundCounts: Counts | null;
  asset: Vertebra | null;
  user: Vertebra | null;
  payment: Vertebra | null;
  identificationRequest: Vertebra | null;
  dividend: Vertebra | null;
  dataChangeRequests: DataChangeRequest[];
  valuations: Valuation[];
  boundNews: News[];
  boundValuations: Valuation[];
  valuation: Vertebra | null;
  newsOperation: Vertebra<News> | null;
  assetSettings: any;
}

export const generateInitialRootState = (): { [key: string]: any } => ({
  // Firestore native bindings
  manager: null,

  // Computed/derived data
  currentManager: new DataContainer<CurrentManager>(),
  login: new DataContainer<any>(),
  verifySMS: new DataContainer<any>(),
  managerStatus: new DataContainer<string>(),
  managerRole: new DataContainer<string>(),
  managerRemoval: new DataContainer<any>(),
  tracing: new DataContainer<Trace[]>(),
  generalSettings: new DataContainer<Settings>(),
  securitySettings: new DataContainer<string>(),
  assetSettings: [],
  backups: new DataContainer<Backup[]>(),
  restoreBackup: new DataContainer<string>(),
  restoreBackupStatus: new DataContainer<string>(),
  passwordReset: new DataContainer<string>(),
  approveSensitiveDataChange: new DataContainer<string>(),
  rejectSensitiveDataChange: new DataContainer<string>(),
  sendTestEmails: new DataContainer<string>(),
  notification: new DataContainer<string>(),
  filters: new Filters(),
  outlineMode: false,
  specialUsers: [],
  settings: null,
  identificationSettings: null,
  restoration: null,
  dataChangeRequests: [],
  openPayments: [],
  loanPayments: [],
  boundInvestments: [],
  boundInvestment: null,
  boundPayments: [],
  boundPayment: null,
  boundAssets: [],
  boundAsset: null,
  boundUsers: [],
  boundUser: null,
  boundIdentificationRequests: [],
  boundCounts: [],
  boundValuations: [],
  valuations: [],
  boundNews: [],
  newsOperation: new DataContainer<string>(),
  uploadedRepayments: [],
  uploadedEarnings: [],
});
