import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { generateState, mutateState, Vertebra } from '@/store/utils/skeleton';
import { Asset } from '@/models/assets/Asset';
import { News } from '@/models/assets/News';
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;

const SET_NEWS = 'SET_NEWS';

export interface UpdateNewsParam {
  assetId: string,
  newsId: string,
  updatedNews: object;
}

export interface DeleteNewsParam {
  assetId: string,
  newsId: string,
}

export interface AddNewsParam {
  assetId: string;
  title: string;
  content: string;
  date: Timestamp;
  mainPageSelect: boolean;
}

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_NEWS](state, { status, payload, operation }: { status: DataContainerStatus, payload?: { [key: string]: unknown }, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addNews(
      { commit }, { assetId, title, content, date, mainPageSelect }: AddNewsParam,
    ): Promise<void> {
      commit(SET_NEWS, {
        status: DataContainerStatus.Processing,
        operation: 'addNews',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId) as DocumentReference<Asset>;
      const newsRef = assetRef.collection('news');
      const dateNow = firebase.firestore.Timestamp.now();

      const [addNewsError, addNewsSuccess] = await to( // write answers to firestore
        newsRef.add({
          asset: assetRef,
          title,
          content,
          date,
          mainPageSelect,
          createdDateTime: dateNow,
          deleted: false,
        }),
      );

      if (addNewsError) {
        return commit(SET_NEWS, {
          status: DataContainerStatus.Error,
          payload: addNewsError,
          operation: 'addNews',
        });
      }

      return commit(SET_NEWS, {
        status: DataContainerStatus.Success,
        payload: addNewsSuccess,
        operation: 'addNews',
      });
    },
    async updateNews(
      { commit },
      { updatedNews, assetId, newsId }: UpdateNewsParam,
    ): Promise<void> {
      commit(SET_NEWS, {
        status: DataContainerStatus.Processing,
        operation: 'updateNews',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const newsRef = assetRef.collection('news').doc(newsId) as DocumentReference<News>;

      const [updateNewsError, updateNewsSuccess] = await to( // write answers to firestore
        newsRef.update(updatedNews),
      );

      if (updateNewsError) {
        return commit(SET_NEWS, {
          status: DataContainerStatus.Error,
          payload: updateNewsError,
          operation: 'updateNews',
        });
      }

      return commit(SET_NEWS, {
        status: DataContainerStatus.Success,
        payload: updateNewsSuccess,
        operation: 'updateNews',
      });
    },
    async deleteNews(
      { commit },
      { assetId, newsId }: DeleteNewsParam,
    ): Promise<void> {
      commit(SET_NEWS, {
        status: DataContainerStatus.Processing,
        operation: 'deleteNews',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const valuationRef = assetRef.collection('news').doc(newsId) as DocumentReference<News>;
      const dateNow = firebase.firestore.Timestamp.now();

      const [deleteNewsError, deleteNewsSuccess] = await to(
        valuationRef.update({
          deleted: true,
          createdDateTime: dateNow,
        }),
      );

      if (deleteNewsError) {
        return commit(SET_NEWS, {
          status: DataContainerStatus.Error,
          payload: deleteNewsError,
          operation: 'deleteNews',
        });
      }

      return commit(SET_NEWS, {
        status: DataContainerStatus.Success,
        payload: deleteNewsSuccess,
        operation: 'deleteNews',
      });
    },
  },
};
